import { createTRPCProxyClient, httpLink } from '@trpc/client'
import type { AppRouter } from '../../../api-zulassung-digital/trpc/routers'
import superjson from 'superjson'

export default defineNuxtPlugin(() => {
  const trpc = createTRPCProxyClient<AppRouter>({
    transformer: superjson,
    links: [
      httpLink({
        url: `${useRuntimeConfig().public.backendUrl}/trpc`,
        fetch(url, options) {
          return fetch(url, {
            ...options,
            credentials: 'include',
          })
        }
      })
    ],
  })

  return {
    provide: {
      trpc
    }
  }
})
