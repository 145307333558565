export default defineNuxtPlugin(() => {
  const googleAnalyticsId = useRuntimeConfig().public.googleAnalyticsId as string
  
  if (!googleAnalyticsId) {
    throw new Error('You must provide googleAnalyticsId in runtimeConfig when extending this layer.')
  }

  useHead({
    script: [
      {
        // type: 'text/partytown',
        src: `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`,
        async: true
      },
      {
        // type: 'text/partytown',
        innerHTML: `
          dataLayer = window.dataLayer || [];

          window.gtag = function () {
              dataLayer.push(arguments);
          };

          window.gtag('js', new Date());
          window.gtag('config', '${googleAnalyticsId}');
        `
      },
    ]
  })

  const serverGtag = (...args) => {
    console.warn('!!! gtag is not available in SSR !!!')
  }

  const clientGtag = (...args) => {
    window?.gtag?.(...args)
  }

  const gtag = import.meta.client ? clientGtag : serverGtag

  return {
    provide: {
      gtag
    }
  }
})
